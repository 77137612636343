import axios from 'axios'
import store from '@/store/index'
import VentasApi from '@/api/venta/venta'
import { imprimirComprobante } from '@/services/comprobante.js'

var controlFormasDePago = () => {
  var formasDePago = store.state.ventas.formasDePagoSeleccionadas.map(object => ({ ...object }))
  //Si llega una forma de pago con monto = null se quita
  for (let index = 0; index < formasDePago.length; index++) {
    if (!formasDePago[index].monto){
      console.log(formasDePago[index], "No tiene monto");
      formasDePago.splice(index,1)
    } 
    
  }
  var total = parseFloat(store.getters['ventas/get_total'])


  var cantidad_formas = formasDePago.length
  var totalPagoCliente = parseFloat(store.getters['ventas/totalPagoCliente'])


  if (totalPagoCliente == total) {
    return formasDePago
  }
  if (cantidad_formas == 1) {
    for (let index = 0; index < formasDePago.length; index++) {
      if (formasDePago[index].nombre == 'EFECTIVO') {
        formasDePago[index].monto = total
      }
      if (formasDePago[index].nombre == 'CTA. CTE.') {
        formasDePago[index].monto = total
      }
    }
    return formasDePago
  }

  if (cantidad_formas == 2) {
    var monto = 0
    for (let index = 0; index < formasDePago.length; index++) {
      if (formasDePago[index].nombre == 'TARJETA DE DÉBITO') {
        if (formasDePago[index].monto == 0) {
          formasDePago.splice(index,1)
        }
        else {
          monto = monto + parseFloat(formasDePago[index].monto)
        }

      }
    }

    for (let index = 0; index < formasDePago.length; index++) {
      if (formasDePago[index].nombre == 'TARJETA DE CRÉDITO') {
        if (formasDePago[index].monto == 0) {
          formasDePago.splice(index,1)
        }
        else {
          monto = monto + parseFloat(formasDePago[index].monto)
        }

      }
    }
    for (let index = 0; index < formasDePago.length; index++) {
      if (formasDePago[index].nombre == 'VOUCHER') {
        if (formasDePago[index].monto == 0) {
          formasDePago.splice(index,1)
        }else {
          if (monto == 0) {
            console.log("Esta en voucher, monto == ",monto);
            if (parseFloat(formasDePago[index].monto) >= total) {
              console.log("El monto del voucher: ",parseFloat(formasDePago[index].monto),". Es mayor que el total: ",total)
              formasDePago[index].monto = total
            }
          }else {

            formasDePago[index].monto = total - monto
          }
        }
      
      }

    }
    for (let index = 0; index < formasDePago.length; index++) {
      
      if (formasDePago[index].nombre == 'EFECTIVO') {
        if (formasDePago[index].monto == 0) {
          formasDePago.splice(index,1)
        }else {

          if (monto == 0) {
              formasDePago[index].monto = total
          }else {

            formasDePago[index].monto = total - monto
          }
        }

      }

    }

    return formasDePago
  }
}

export default async function registrarPedidoVenta(data) {

  //Guardo en el array 'detalles_formato_server'  el formato correcto para enviar al server
  var detalles_formato_server = []
  console.log(data.detalles);
  
  for (let i = 0; i < data.detalles.length; i++) {
    if (data.detalles[i].producto_sucursal) {
      detalles_formato_server.push({
        producto: data.detalles[i].producto_sucursal.id,
        cantidad: data.detalles[i].cantidad,
      });
    } else {
      console.log(data.detalles[i].promocion);
      
      detalles_formato_server.push({
        promocion: data.detalles[i].promocion.id,
        cantidad: data.detalles[i].cantidad,
      });
    }
  }


  var formData = {
    detalle_venta: detalles_formato_server,
    nombreEntidad: data.nombreEntidad,
    cliente: data.cliente,
    pedido: data.pedido,
    formaDePago: controlFormasDePago(),
    descuento: data.descuento ? data.descuento : 0,
    motivoDescuento: data.motivoDescuento,
    recargo: data.recargo ? data.recargo : 0,
    pagaCon: data.pagoCliente,
    esParaFacturar: data.esParaFacturar,
    esPedidoYa: store.state.remitos.esPedidoYa
  }
  console.log(formData);
  formData.documento = data.esParaFacturar ? 'FC' : 'NV'



  return new Promise((resolve, reject) => {
    VentasApi.create(formData).then(response => {
      resolve(response);
      store.dispatch('notificaciones/fetch_notificaciones', 1)
      var menupedidocruachan = store.state.sucursales.configuracion.menuVentaCruachan
      if (menupedidocruachan){
        return
      }
      
      imprimirComprobante(response.data.id).then(response => {
        console.log("OK", response);
      }).catch(e => {
        console.log(e);
      })


      // context.commit('add_pedido', response.data)
      // context.commit('set_bandera_success_register')
      // context.commit('reset_state_pedido_compra')


    }, error => {

      reject(error);
    })

  })


}

export { controlFormasDePago }