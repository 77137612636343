import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from '@/store/index'
import router from '@/router'

// var baseURL = 'https://api.legorsoft.click/api/v1';
var baseURL = 'http://127.0.0.1:80/api/v1';


// Verifica si el entorno es producción y si se proporciona una URL de backend personalizada
if (process.env.NODE_ENV === "production") {
    if (process.env.VUE_APP_BACKEND_URL) {
        // Utiliza la URL de backend personalizada si está disponible en producción
        baseURL = process.env.VUE_APP_BACKEND_URL;
    }
}

// Verifica si el entorno es desarrollo y si se proporciona un host personalizado
if (process.env.NODE_ENV === "development") {
    if (process.env.HOST) {
        // Construye la URL de la API en desarrollo utilizando el host personalizado
        baseURL = `http://${process.env.HOST}/api/v1`;
    }
}

axios.defaults.baseURL = baseURL;
axios.defaults.withCredentials = true;

Vue.use(VueAxios, axios);

// URL para solicitar la renovación del token de acceso
const TOKEN_REFRESH_URL = '/usuarios/token/refresh';

// URL para solicitar un nuevo token de acceso
const TOKEN_URL = '/usuarios/token';

// URL para realizar el cierre de sesión
const LOGOUT_URL = '/usuarios/logout';

// Cola de solicitudes pendientes que esperan la renovación del token de acceso
let requestsQueue = [];
// Indicador para controlar si se está realizando actualmente la renovación del token
let isRefreshing = false;

// Obtiene el token de actualización del almacenamiento local
function getTokenRefresh(localStorage) {
    return localStorage.getItem('token_refresh');
}

// Obtiene el token de acceso del almacenamiento local
function getTokenAccess(localStorage) {
    return localStorage.getItem('token_access');
}

// Guarda el nuevo token de acceso en el almacenamiento local
function saveTokenAccess(newTokenAccess) {
    localStorage.setItem('token_access', newTokenAccess);
}

// Realiza una solicitud para obtener un nuevo token de acceso
async function getNewAccessToken(tokenRefresh) {
    try {
        await axios.post(TOKEN_REFRESH_URL, { refresh: tokenRefresh });
    } catch (error) {
        console.error('Error al obtener el nuevo token de acceso:', error);
        throw new Error('Error al obtener el nuevo token de acceso: ' + error.message);
    }
}

// Encola una solicitud pendiente para ser ejecutada posteriormente
function enqueueRequest(request) {
    return new Promise((resolve, reject) => {
        requestsQueue.push({ request, resolve, reject });
    });
}

// Maneja el cierre de sesión en caso de expiración de la sesión o falta de tokens
function handleTokenRefreshLogout(errorMessage) {
    if (!errorMessage) {
        errorMessage = 'Su sesión ha expirado';
    }
    store.dispatch('auth/logOut', errorMessage);
}

// Resuelve las solicitudes pendientes en la cola
function resolveRetryQueue(response) {
    requestsQueue.forEach(({ resolve }) => {
        try {
            resolve(response);
        } catch (error) {
            console.error('Error al resolver una solicitud pendiente:', error);
        }
    });
    requestsQueue = [];
}

// Maneja las solicitudes pendientes cuando se renueva el token de acceso
async function handlePendingRequests(token) {
    while (requestsQueue.length > 0) {
        const { request, resolve, reject } = requestsQueue.shift();
        request.headers.Authorization = `Bearer ${token}`;

        try {
            const response = await axios(request);
            resolve(response);
        } catch (error) {
            console.error("Error al ejecutar solicitud encolada:", request.method, request.url, error);
            reject(error);
        }
    }
}

// Fuente de cancelación global para Axios
const globalCancelTokenSource = axios.CancelToken.source();

// Interceptor de solicitudes de Axios
axios.interceptors.request.use(async (config) => {
    if (config.url === TOKEN_URL || config.url === LOGOUT_URL || config.url === TOKEN_REFRESH_URL) {
        return config; // No hagas nada especial para estas solicitudes
    }

    const token = getTokenAccess(localStorage);
    const refreshToken = getTokenRefresh(localStorage);

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    } else if (refreshToken) {
        if (!isRefreshing) {
            isRefreshing = true;
            try {
                await getNewAccessToken(refreshToken);
                const token = getTokenAccess(localStorage);
                config.headers.Authorization = `Bearer ${token}`;
                isRefreshing = false
                handlePendingRequests(token)
                return config
            } catch (refreshError) {
                return handleTokenRefreshLogout('Su sesión ha expirado debido a que no se pudo obtener el refresh token');
            } finally {
                isRefreshing = false;
            }
        } else {
            return enqueueRequest(config);
        }
    } else {
        handleTokenRefreshLogout('Su sesión ha expirado debido a la falta de tokenRefresh');
        throw new axios.Cancel('Operation canceled by the user.');
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

// Interceptor de respuestas de Axios
axios.interceptors.response.use(
    (response) => {
        if (response.config.url == TOKEN_REFRESH_URL) {
            let newTokenAccess = response.data.access
            saveTokenAccess(newTokenAccess);
            return response
        }
        return response;
    },
    async (error) => {
        if (error.message === 'Network Error') {
            // Manejar el error de falta de conexión a internet
            return Promise.reject(error);
        }

        if (
            error.config &&
            error.response &&
            error.response.status === 401 &&
            !error.config.__isRetry
        ) {
            error.config.__isRetry = true;
            if (error.config.url === TOKEN_REFRESH_URL) {
                handleTokenRefreshLogout('Inicie sesión de nuevo');
                return Promise.reject(error);
            }
            if (error.config.url === TOKEN_URL) {
                return Promise.reject(error);
            }
            if (!isRefreshing) {
                isRefreshing = true;
                try {
                    const refreshToken = getTokenRefresh(localStorage);
                    await getNewAccessToken(refreshToken);
                    let newTokenAccess = getTokenAccess(localStorage)
                    error.config.headers.Authorization = `Bearer ${newTokenAccess}`;
                    const response = await axios(error.config);
                    isRefreshing = false
                    handlePendingRequests(newTokenAccess);
                    return response
                } catch (refreshError) {
                    return Promise.reject(error);
                } finally {
                    isRefreshing = false;
                }
            } else {
                return enqueueRequest(error.config)
            }
        }
        return Promise.reject(error);
    }
);
