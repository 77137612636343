import Vue from 'vue'
import router from '@/router'
import getComprobantes from "@/services/comprobante";
import REMITO_COMPRA_API from "@/api/compra/remito.js";




//en contexto tenemos todo el objeto completo

export function registrar_remito_compra(context, { nroRemito, sucursalProveedor, pedido, proveedor, detalles,estaPago }) {
    // context.commit('setLoading', true, { root: true }) //root true, se define la mutacion en la mutacion root
    var detalles_formato_server = []

    //Guardo en el array 'detalles_formato_server'  el formato correcto para enviar al server
    for (let i = 0; i < detalles.length; i++) {
        detalles_formato_server.push({ producto: detalles[i].producto.id, iva_compra: detalles[i].vat_type, cantidad: detalles[i].cantidad, precioCosto: detalles[i].precioUnitario  })
    
    }
    var formData = {
        detalle_compra: detalles_formato_server,
        numero: nroRemito,
        proveedor: proveedor.id,
        pedido: pedido ? pedido.id : 0,
        sucursal_proveedor: sucursalProveedor,
        estaPago: estaPago,
    }
    return REMITO_COMPRA_API.create(formData)
   
   
}
export function registrar_factura_compra(context, data) {
    // context.commit('setLoading', true, { root: true }) //root true, se define la mutacion en la mutacion root
    var detalles_formato_server = []
    var {detalles } = data
    //Guardo en el array 'detalles_formato_server'  el formato correcto para enviar al server
    for (let i = 0; i < detalles.length; i++) {
        detalles_formato_server.push({ producto: detalles[i].producto.id,iva_compra: detalles[i].vat_type, cantidad: detalles[i].cantidad, precioCosto: detalles[i].precioUnitario  })
    }
    var { numero, proveedor, remito, sucursal, estaPago, documento, letra, percepcionIB, percepcionIva, netoNoGravado, totalesIva }  = data

    var formData = {
        detalle_compra: detalles_formato_server,
        numero: numero,
        proveedor: proveedor.id,
        comprobanteAsociado: remito ? remito.id : null,
        sucursal_proveedor: sucursal,
        estaPago: estaPago,
        documento: documento,
        letra: letra,
        percepcion_ib: percepcionIB,
        percepcion_iva: percepcionIva,
        neto_no_gravado: netoNoGravado,
        totales_iva: totalesIva

    }
    var url = '/comprobantes/compra'
    return Vue.axios({
        method: 'POST',
        url: url,
        data: formData,
    })
   
}

export function get_remito_compra(context, id) {
    return REMITO_COMPRA_API.show(id)
   
}



export function pagar_remito(context, nroRemito) {

    return REMITO_COMPRA_API.payment(nroRemito)
  
   
}

export async function fetch_pedidos(context, { tipoComprobante, estado }) {

    try {

        context.commit('setLoading', true, { root: true }) //root true, se define la mutacion en la mutacion root
        var url = `/pedidos/estado/${estado}`;
        if (estado == 'pendiente') {
            await Vue.axios({
                method: 'GET',
                url: url,
            }).then(response => {
                
                context.commit('set_pedidos_pendientes', response.data)
            })
        } else if (estado == 'aprobado') {
            await Vue.axios({
                method: 'GET',
                url: url,
            }).then(response => {
                
                context.commit('set_pedidos_aprobados', response.data)
            })
        } else if (estado == 'finalizado') {
            await Vue.axios({
                method: 'GET',
                url: url,
            }).then(response => {
                
                context.commit('set_pedidos_finalizados', response.data)
            })
        }

    } catch (error) {
        context.commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root

    } finally {
        context.commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root

    }

}
export async function get_pedido_con_detalle(context, id) {
    try {

        context.commit('setLoading', true, { root: true }) //root true, se define la mutacion en la mutacion root


        var url = `/pedidos/${id}`;


        await Vue.axios({
            method: 'GET',
            url: url,
        }).then(response => {
            context.commit('set_pedido_con_detalle', response.data)
        })


    } catch (error) {

        context.commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root

    } finally {
        context.commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root

    }

}


export async function fetch_remitos(context,estado){

      try {
        context.commit('setLoading', true, { root: true }) //root true, se define la mutacion en la mutacion root

        var response = await getComprobantes(false, "remito", this.paginacion);
        context.commit('set_remitos_compra',response.data)

        this.loading = false;
        context.commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root

      } catch (error) {
        context.commit('setLoading', false, { root: true }) //root true, se define la mutacion en la mutacion root

      }
}